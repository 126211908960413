import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Menu, X, Globe, Search, User } from 'lucide-react';
import BackgroundImage from './assets/bg.png';
import Logo from './assets/logo.png';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const isHomePage = currentPath === '/home' || currentPath === '/';

  const navigation = ['Home', 'Services', 'Courses', 'Portfolio', 'About', 'Contact'];

  const handleBookMeeting = () => {
    window.open('https://doodle.com/bp/ocenasmartsolutions/meeting', '_blank');
  };

  // Function to generate breadcrumb path
  const getBreadcrumb = () => {
    const path = currentPath.split('/').filter(item => item);
    if (path.length === 0) return [{ text: 'Home', path: '/' }];
    
    return [
      { text: 'Home', path: '/' },
      ...path.map((item, index) => ({
        text: item.charAt(0).toUpperCase() + item.slice(1),
        path: '/' + path.slice(0, index + 1).join('/')
      }))
    ];
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (isHomePage) {
    return (
      <div className="w-full">
        {/* Top banner */}
        <div className="w-full bg-blue-100 p-3 text-center text-gray-700 hidden md:block">
          Blockchain : A decentralized, secure, and transparent digital ledger technology for recording transactions.
          <div className="absolute right-4 top-3 flex items-center gap-4">
            <Globe className="h-5 w-5" />
            <Search className="h-5 w-5" />
            <User className="h-5 w-5" />
          </div>
        </div>

        {/* Main navigation */}
        <nav className="flex items-center justify-between bg-gradient-to-r from-blue-50 to-purple-50 px-8 py-4">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-2">
            <img src={Logo} alt="OCENA Logo" className="h-20 w-20" />
            <div>
              <h1 className="text-xl font-semibold">OCENA</h1>
              <p className="text-sm text-gray-600">smart solutions</p>
            </div>
          </Link>

          {/* Navigation links */}
          <div className="hidden md:flex gap-8">
            {navigation.map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className="text-gray-700 hover:text-blue-600"
              >
                {item}
              </Link>
            ))}
          </div>

          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu}
            className="md:hidden text-gray-700"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Book a meeting button */}
          <button 
            onClick={handleBookMeeting}
            className="hidden md:flex rounded-full border-2 border-blue-500 px-4 py-2 text-blue-500 hover:bg-blue-500 hover:text-white"
          >
            Book a meeting →
          </button>
        </nav>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white shadow-lg">
            {navigation.map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className="block py-2 px-8 text-gray-700 hover:text-blue-600"
                onClick={() => setIsMenuOpen(false)}
              >
                {item}
              </Link>
            ))}
            <div className="p-4">
              <button 
                onClick={handleBookMeeting}
                className="w-full rounded-full border-2 border-blue-500 px-4 py-2 text-blue-500 hover:bg-blue-500 hover:text-white"
              >
                Book a meeting →
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  // Return the purple header for other pages
  return (
    <div className="relative w-full h-[200px] md:h-[300px]">
      {/* Background image with overlay */}
      <div 
        className="absolute inset-0 w-full h-full bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundBlendMode: 'overlay',
          backgroundColor: 'rgba(109, 40, 217, 0.85)'
        }}
      />

      {/* Header content */}
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:py-6">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <img src={Logo} alt="OCENA Logo" className="h-20 w-20" />
            <div className="text-white">
              <div className="text-xl font-bold">OCENA</div>
              <div className="text-sm">smart solutions</div>
            </div>
          </Link>

          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu}
            className="md:hidden text-white hover:text-purple-200 transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            {navigation.map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className={`text-white hover:text-purple-200 transition-colors ${
                  currentPath === `/${item.toLowerCase()}` ? 'font-bold' : 'font-medium'
                }`}
              >
                {item}
              </Link>
            ))}
          </nav>

          {/* Book a meeting button */}
          <button 
            onClick={handleBookMeeting}
            className="hidden md:flex bg-white text-purple-700 px-6 py-2 rounded-full font-medium hover:bg-purple-100 transition-colors items-center"
          >
            Book a meeting
            <svg
              className="w-4 h-4 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden bg-white absolute left-0 right-0 top-16 p-4 shadow-lg rounded-b-lg">
            {navigation.map((item) => (
              <Link
                key={item}
                to={`/${item.toLowerCase()}`}
                className={`block py-2 text-purple-700 hover:text-purple-900 transition-colors ${
                  currentPath === `/${item.toLowerCase()}` ? 'font-bold' : 'font-medium'
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                {item}
              </Link>
            ))}
            <button 
              onClick={handleBookMeeting}
              className="w-full mt-4 bg-purple-700 text-white px-6 py-2 rounded-full font-medium hover:bg-purple-800 transition-colors flex items-center justify-center"
            >
              Book a meeting
              <svg
                className="w-4 h-4 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        )}

        {/* Breadcrumb */}
        <div className="pb-12 pt-6 flex justify-center">
          <div className="text-center">
            <h1 className="text-2xl md:text-4xl font-bold text-white mb-3">
              {getBreadcrumb().slice(-1)[0].text}
            </h1>
            <div className="flex items-center justify-center text-purple-200 text-sm md:text-base">
              {getBreadcrumb().map((item, index, array) => (
                <React.Fragment key={item.path}>
                  <Link
                    to={item.path}
                    className="hover:text-white transition-colors"
                  >
                    {item.text}
                  </Link>
                  {index < array.length - 1 && (
                    <span className="mx-2">{'>'}</span>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
