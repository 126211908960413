import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';

import Courses from './pages/courses/courses';
import FrontendInfo from './pages/courses/coursepage/frontend';
import BackendInfo from './pages/courses/coursepage/backend';
import FullstackInfo from './pages/courses/coursepage/fullstack';
import BlockchainInfo from './pages/courses/coursepage/blockchain';

import About from './pages/About/About';
import Contact from './pages/Contact/Contact';

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/About" element={<About />} />

          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/frontend" element={<FrontendInfo />} />
          <Route path="/courses/backend" element={<BackendInfo />} />
          <Route path="/courses/fullstack" element={<FullstackInfo />} />
          <Route path="/courses/blockchain" element={<BlockchainInfo />} />

          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;