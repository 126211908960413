import React, { useEffect, useState } from 'react';
import Tree from './assets/dots.jpg'; 
const HeroSection = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTextVisible, setIsTextVisible] = useState(true);
  const textOptions = ['For Businesses', 'For Enterprises', 'For Organizations'];

  useEffect(() => {
    const textInterval = setInterval(() => {
      setIsTextVisible(false);
            setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textOptions.length);
        setIsTextVisible(true);
      }, 500); 
      
    }, 3000); 
  
    return () => clearInterval(textInterval);
  }, [textOptions.length]);  
  

  const handleGetStarted = () => {
    const nextSection = document.getElementById('next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleWatchVideo = () => {
    window.open('https://www.instagram.com/reel/DCqa-sQtY5Q/', '_blank');
  };

  return (
    <div>
      <div className="bg-black text-white min-h-screen flex items-center p-4 sm:p-6 md:p-8">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between space-y-8 lg:space-y-0">
          <div className="lg:w-1/2 text-center lg:text-left w-full">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-4">
              World-Class Blockchain Solutions{' '}
              <span 
                className={`text-purple-500 inline-block transition-opacity duration-500 ${
                  isTextVisible ? 'opacity-100' : 'opacity-0'
                }`}
              >
                {textOptions[currentTextIndex]}
              </span>
            </h1>
            <p className="text-base sm:text-lg lg:text-xl mb-6 sm:mb-8">
              Protect, Monitor and grow with our Advanced Security Tools
            </p>
            <div className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4">
              <button 
                onClick={handleGetStarted}
                className="w-full sm:w-auto bg-gradient-to-r from-purple-600 to-purple-800 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:opacity-90 transition duration-300"
              >
                Get Started →
              </button>
              <button 
                onClick={handleWatchVideo}
                className="w-full sm:w-auto bg-gradient-to-r from-blue-400 to-blue-600 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-full font-semibold hover:opacity-90 transition duration-300"
              >
                Watch Now ▶
              </button>
            </div>
          </div>
          <div className="lg:w-1/2 w-full max-w-md lg:max-w-full">
            <img 
              src={Tree}  
              alt="Blockchain Network Tree" 
              className="w-full h-auto object-contain"
            />
          </div>
        </div>
      </div>
      <section id="next-section">
      </section>
    </div>
  );
};

export default HeroSection;
